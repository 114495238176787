<template>
<div>
  <CCard>
    <CCardHeader>
      <h4 style="color:#00A8FF">{{ site.name }} / {{ device.name }}</h4>
    </CCardHeader>

    <CCardBody>
      <CRow>
        <div class="col-sm-12 col-lg-5">
          <div id="device-img" class="carousel slide carousel-fade" data-ride="carousel" data-interval="3000">
            <ol class="carousel-indicators">
              <li data-target="#device-img" :data-slide-to="index" v-for="(img, index) in deviceImages" :key="index" :class='{active:index==0}'></li>
            </ol>
            <div class="carousel-inner">
              <div class="carousel-item" v-for="(img, index) in deviceImages" :key="index" :class='{active:index==0}'>
                <img class="d-block w-100" :src="img.url" :style="image_style" @click="openUploadDialog()" alt="">
                <div class="carousel-caption">
                  <p>{{img.label}}<br>{{img.created_at}}</p>
                </div>
              </div>
            </div>
            <a class="carousel-control-prev" href="#device-img" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
            <a class="carousel-control-next" href="#device-img" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
          </div>
        </div>
        <div class="col-sm-12 col-lg-7">
          <div class='table'>
            <v-table id="deviceTable" :columns="columns" :data="data" :options="options" style="height: 100%;"></v-table>
          </div>
        </div>
      </CRow>
    </CCardBody>
    <CCardFooter col="12" xl="12">
      <CButton color="danger" class="float-right" v-show="service_type == 'sis.leaf'" @click="openCameraDialog()">ZOOM</CButton>
      <CButton color="success" class="float-right mr-1" @click="openDialog('battery')" >배터리 기준 설정</CButton>
      <CButton color="primary" class="float-right mr-1" v-show="service_type == 'air'" @click="openDialog('pms')">초미세먼지 기준 설정</CButton>
      <CButton color="primary" class="float-right mr-1" v-show="service_type == 'srds'" @click="openDialog('srds')">위험등급 설정</CButton>
      <CButton color="info" class="float-right mr-1" v-show="checksisservice == true" @click="openDialog('humidity')">수분량 기준 설정</CButton>
      <CButton color="warning" class="float-right mr-1" @click="openDialog('sensor')">센서 정보 관리</CButton>
      <CButton color="info" class="float-right mr-1" v-show="service_type == 'srds'" @click="openDialog('incline')">기울기 기준값 설정</CButton>
    </CCardFooter>
  </CCard>

  <Confirm
      ref="confirmDialog"
      title="확인"
      @hide="hideModal"
      color="warning"
  />
  <DeviceInfo
      ref="deviceInfo"
      :isAdmin="is_admin"
      :isMobile="is_mobile"
      :device.sync="device"
      @hide="hideModal"
      @update="onUpdated"
  />
  <Threshold
      ref="thresholdDialog"
      :isAdmin="is_admin"
      :isMobile="is_mobile"
      :device.sync="device"
      @update="onUpdated"
  />
  <SRDSThreshold
      ref="srdsthresholdDialog"
      :isAdmin="is_admin"
      :isMobile="is_mobile"
      :device.sync="device"
      @update="onUpdated"
  />
  <Incline 
      ref="srdsInclineDialog"
      :isAdmin="is_admin"
      :isMobile="is_mobile"
      @update="onUpdated"
  />
  <Uploader
      id="imageUploader"
      ref="uploaderDialog"
      :device_guid="device_guid"
      :storage.sync="storage"
      @hide="hideModal"
  />
  <CameraEditor
    ref="cameraeditor"
    :editFile.sync="editFile"
    :device_guid="device_guid"
    :storage.sync="storage"
    @hide="hideModal"
  />
</div>
</template>

<script>
import loopback from '@/services/loopback';
import moment from 'moment';
import BootstrapTable from 'bootstrap-table/dist/bootstrap-table-vue.esm';
import EventBus from '@/services/EventBus'
import utils from '@/services/utils';
import CronParser from 'cron-parser'

import {
  DEVICE_DETAIL_OPTIOINS, DEVICE_DETAIL_COLUMS, LEAF_TYPES
} from "@/constants/deviceSettings";

import DeviceInfo from '@/views/device/DeviceInfo'
import Threshold from '@/views/device/Threshold'
import SRDSThreshold from '@/views/srds/device/SRDSThreshold'
import Uploader from '@/views/device/Uploader'
import CameraEditor from '@/views/device/CameraEditor'

// 기울기 기준값 설정
import Incline from '@/views/srds/device/Incline'

const DEFAULT_SERVICE_TYPE = 'sis'
var SERVICE_TYPE = utils.getServiceTypes();

export default {
  name: 'Device',
  components: {
    DeviceInfo,
    Threshold,
    SRDSThreshold,
    Uploader,
    CameraEditor,
    Incline
  },
  props: {
    device_guid: {
      type: String,
      default: ''
    },
    device: {
      type: Object
    }
  },
  created: function() {
    this.user_info = this.$store.state.auth.user_info;
    this.user = this.user_info.user
    this.sites = this.user_info.sites

    _.find(this.columns, { field: 'value' }).formatter = this.dataFormatter;
    utils.loadThresholds(res => this.thresholds = res)

    var image_height = 100;
    this.image_style = {
      'width': '100%',
      'min-height': image_height + 'px',
      'max-height': image_height + 'px'
    }

    EventBus.$on('alarmData', this.onEvent);
    EventBus.$on('reload', this.reloadListener);
  },
  beforeDestroy(){
    EventBus.$off('alarmData', this.onEvent);
    EventBus.$off('reload', this.reloadListener)
  },
  data () {
    return {
      site: {
        name: 'tech9'
      },
      sensor: {},
      deviceImages: [],
      image_style: {},
      thresholds: [],
      service_type: DEFAULT_SERVICE_TYPE,
      is_admin: this.$store.state.auth.is_admin,
      is_mobile: this.$is_mobile,
      checksisservice: false,
      storage: "storages",
      options: DEVICE_DETAIL_OPTIOINS,
      columns: DEVICE_DETAIL_COLUMS,
      data: [],
      editFile: {
        show: false,
        name: '',
      }
    }
  },
  watch: {
    device: function(new_val, old_val) {
      // console.log('Device::watch device[guid] - ', new_val.guid)
      new_val.install_date = new_val.install_date || new Date(moment('2017-06-21').utc());
      this.service_type = new_val.service_type;
      switch (new_val.service_type) {      
        case 'sis':
        case 'sis.valve': this.checksisservice = true; break;
        case 'sis.leaf': this.storage = 'leafPhotos'; this.checksisservice = true; break;
        case 'srds': case 'srds.dual': case 'swfm': case'door': case 'fumax': case 'fumax.snow': case 'btfs' : this.storage = 'flowPhotos'; break;
      }
      this.getSensors();
      this.getImages();
      this.getContrlImages();
    }
  },
  methods: {
    reloadListener() {
      //console.log('Device::reloadListener')
      this.getSensors();
      this.getImages();
      this.getContrlImages();      
    },
    onEvent(evt_message) {
      for (var i = 0; i < evt_message.message.datas.length; i++) {
        var elem = evt_message.message.datas[i];
        if (this.device_guid === elem.data.device_guid) {
          console.log("Device::onEvent : this.device_guid[%s], data.device_guid[%s]", this.device_guid, elem.data.device_guid);
          this.getSensors();
          this.getImages();
          this.getContrlImages();          
          return
        }
      }
    },
    openUploadDialog() {
      this.$refs.uploaderDialog.show();
    },
    openCameraDialog() {
      this.$refs.cameraeditor.show(this.editFile);
    },
    onUpdated(event) {
      // updated event must be delivered to parent because "device" is [props]
      this.$emit('update', event)
    },
    resetData() {
      var message = "센서데이터 : " + this.device.name + " 를 초기화 하시겠습니까?"
      this.$refs.confirmDialog.show(message, '데이터 초기화', undefined, 'reset');      
    },
    hideModal(event) {
      if (event.name === 'Uploader' && event.which === 'update') {
        if (event.data.need_refresh)
          this.getImages();
        return
      } else
      if (event.data !== 'confirm' || event.which !== 'reset') {
        return; // cancel
      }
      var data = {
        device_guid: this.device.guid
      }
      this.$store.dispatch('rest/method',{model:'devices',method:'deleteDeviceData',data:data})
        .then(res => this.$emit('reset', res))
        .catch(err => {
          console.log('Device::reset error:', err.toString())
        })
    },
    openDialog(which) {
      var info = {
        device: this.device,
        site: this.site,
        service_type: DEFAULT_SERVICE_TYPE,
        thresholds: _.cloneDeep(_.filter(this.thresholds, o => o.service_type === DEFAULT_SERVICE_TYPE))
      }
      switch (which) {
        case 'sensor':
          this.$refs.deviceInfo.show(_.isEmpty(this.sensor) ? [] : this.sensor);
          break;
        case 'humidity':
          info.type = 12; info.id = 1;
          this.$refs.thresholdDialog.show(info, '수분량');
          break;
        case 'battery':
          info.type = 1; info.id = 1;
          this.$refs.thresholdDialog.show(info, '배터리');
          break;
        case 'pms':
          info.type = 28; info.id = 6;
          this.$refs.thresholdDialog.show(info, '미세먼지');
          break;        
        case 'srds':
          info.type = 8; info.id = 1;
          this.$refs.srdsthresholdDialog.show(info, '위험등급');
          break;
        case 'incline':
          info.type = 8; info.id = 1;
          this.$refs.srdsInclineDialog.show(info, "기울기 기준값")
          break;       
      }
    },
    setupImageStyle(count) {
      var row_height = 50;
      var image_height = row_height * count;
      if (image_height > 460)
        image_height = 460
      this.image_style = {
        'width': '100%',
        'min-height': image_height + 'px',
        'max-height': image_height + 'px'
      }
    },
    getThresholdName(name) {
      var result = "";
      if (typeof name === "undefined")
        return result;
      var new_name = name.split("-").map(function(val) {
        return val
      });
      result = new_name && new_name.length > 1 ? new_name[1] : new_name[0];
      return result;
    },
    dataFormatter(value, row, index) {
      if (_.has(row, "threshold")) {
        var img_string = '';
        if (!_.isEmpty(row.threshold.img)) {
          img_string = '<img class="img-responsive" src=' + _.get(row, "threshold.img.name", "") + ' width="25pt" height="20pt"/>'
        }
        var result = '<div class="containerBox"> ' +
          img_string +
          '<div class="threshold-text-box">' +
          ' <h6 class="threshold-dataNumber">' + this.getThresholdName(_.get(row, "threshold.name")) + '</h6>' +
          '</div>' +
          '</div> &nbsp;' + value
        return result;
      } else {
        return value;
      }
    },
    cronStringFormatter(x){  
      ///if (x.equals('cron')') return "1 시간" 
      if( x  === '*/1 * * * *') return "1 분" 
      else if( x  === '*/5 * * * *') return "5 분"       
      else if( x  === '*/10 * * * *') return "10 분" 
      else if (x === '*/20 * * * *') return "20 분"       
      else if (x === '*/30 * * * *') return "30 분" 
      else if (x === '0 */1 * * *') return "1 시간" 
      else if (x === '0 */3 * * *') return "3 시간" 
      else if (x === '0 */8 * * *') return "8 시간(8,16,24)" 
      else if (x === '0 6,14,22 * * *') return "8 시간(6,14,22)" 
      else  
      return "no value" 
    }, 
    wateringStringFormatter(x){  
      ///if (x.equals('cron')') return "1 시간" 
      if( x  === '') return "자동관수 정지" 
      else if( x  === '*/1 1 1') return "1 분" 
      else if (x === '*/1 2 1') return "2 분" 
      else if (x === '*/1 5 1') return "5 분" 
      else if (x === '*/1 10 1') return "10 분" 
      else if (x === '*/1 30 1') return "30 분" 
      else if (x === '*/1 60 1') return "1 시간" 
      else if (x === '*/1 120 1') return "2 시간" 
      else if (x === '*/1 125 1') return "2시간 5분 "             
      else  
      return "no value" 
    }, 
    getTableControl() {
      var self = this;
      // var getNetworkModule = function(device) {
      //   var result = "";
      //   var model = _.get(device, "network_module.model", "-");
      //   //var version = _.get(device, "network_module.version", "-");
      //   result += model;
      //   if (self.$is_mobile)
      //     result += '<br>'
      //   //result += " 버전:" + version;
      //   return result;
      // }

      var rows = []
      var device = this.device;
      rows.push({
        "name": "제품 번호",
        "value": _.get(device, "deviceid") + device.guid
      })
      rows.push({
        "name": "설치 일자",
        value: moment(_.get(device, "install_date", "")).format("YYYY-MM-DD HH:mm:ss")
      })
      rows.push({
        "name": "설치 주소",
        "value": _.get(device, "address", "")
      })

      var position = '';
      if (_.get(device, 'latitude')) {
        position += '위도:' + _.get(device, "latitude", "-");
      }
      if (_.get(device, 'longitude')) {
        position += ' '
        if (self.$is_mobile) {
          position += '<br>'
        }
        position += '경도:' + _.get(device, "longitude", "-");
      }
      rows.push({
        "name": "GPS 위치",
        "value": position
      })
      var cron_string = _.get(device, 'upload_cron');
      if (cron_string && cron_string !== "") {
        var cronArray = cron_string.replace(/\s+/g, " ").split(" ");
        if (cronArray.length == 6) {
          cron_string = cronArray.slice(1).join(' ');
        }
        rows.push({
          "name": this.$is_mobile ? "데이터 <br>업로드 주기" : "데이터 업로드 주기",
          "value": this.cronStringFormatter(cron_string)
        })
      } else {
        rows.push({
          "name": this.$is_mobile ? "데이터 <br>업로드 주기" : "데이터 업로드 주기",
          "value": _.get(device, "upload_period")
        })
      }
      var network_type = _.get(device, "network_type", "");
      var service_type = _.get(device, "service_type", "sis")
      //var model = _.get(device, "network_module.model", "");
      var water_level = _.get(device, "water_level", -1);
      var powering_cron = _.get(device, "powering_cron", "");
      var nutrient_level = _.get(device, "nutrient_level", -1);
      var nutrient_cron = _.get(device, "nutrient_cron", "");

      rows.push({
        name: "네트웍 타입",
        value: network_type
      })
      if (water_level != -1 && powering_cron != "" ) {
        if(service_type == 'sis'){
          rows.push({
            name: "저수조 수위(L)",
            value: water_level
          })
        }
        rows.push({
          name: "물공급 주기",
          value: this.wateringStringFormatter(powering_cron)
        })
      }
      if (network_type != 'LoRa' && nutrient_level != -1 && nutrient_cron != "" && service_type == "protect") {
        rows.push({
          name: "양액통 수위(L)",
          value: nutrient_level
        })
        rows.push({
          name: "양액공급 주기",
          value: nutrient_cron + ' [주기(분) 기간(분) 횟수]'
        })
      }
      rows.push({
        name: "서비스 타입",
        value: _.get(SERVICE_TYPE.DEVICE, device.service_type, '-')
      })
      var interval = CronParser.parseExpression(cron_string);
      var due_date = new Date(interval.prev());
      var measure_date = new Date(_.get(this.sensor, "created_at", ""));
      var due_format = due_date.getTime() <= measure_date.getTime() ? '{0}' : '<span style="color: red;">{0}</span>';
      var str_due_date = due_format.format(moment(due_date).format("YYYY-MM-DD HH:mm"));
      var str_measure_date = moment(measure_date).format("YYYY-MM-DD HH:mm:ss");
      rows.push({
        name: "최근측정일",
        value: "{0}({1})".format(str_measure_date, str_due_date)
      })

      var data = this.sensor.data;
      if (data && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          var name = utils.getSensorTypeName(data[i].type, data[i].id, false);
          var device_thresholds = device.threshold;
          //var threshold = utils.getThresholdByValue(data[i], this.thresholds);
          var threshold = utils.getThresholdByValue(data[i], _.isEmpty(device.threshold) ? this.thresholds : device_thresholds);
          //var threshold = undefined;
          var datavalue = data[i].value;

          if (data[i].type == 26)
            datavalue = data[i].value + "(" + utils.getWindDirectionName(data[i].value) + ")"; //"NW";
          else if (data[i].type == 28 && (data[i].id == 6 || data[i].id == 7))
            datavalue = utils.getPMlevelName(data[i].value, data[i].id); //"NW";
          else if(data[i].type == 18)
            datavalue = data[i].value == 1 ? '<span style="color: red; font-weight:bold;">열림</span>':'닫힘';   
          else if(data[i].type == 9 && service_type == 'swfm'){
            name = '연기감지';
            datavalue = data[i].value == 1 ? '<span style="color: red; font-weight:bold;">감지</span>':'미감지';
          }
          else if(data[i].type == 1 && service_type == 'swfm'){
            var percentage = 0;
              if(data[i].value > 4) percentage = 100;
              else if(data[i].value > 3.7 && data[i].value <= 4) percentage = 80;
              else if(data[i].value > 3.6 && data[i].value <= 3.7) percentage = 50;
              else if(data[i].value > 3.5 && data[i].value <= 3.6) percentage = 30;
              else if(data[i].value > 3.4 && data[i].value <= 3.5) percentage = 10;
              else if(data[i].value <= 3.4) percentage = 5;
              else percentage = 0;
              
            datavalue = "<span style='font-weight:bolder'>" + percentage + "% </span>" + "(" + data[i].value + "v)" ;
          }
          if (name && name.length > 0) {
           if(!_.isArray(data[i].value)){
              rows.push({
                name: name,
                value: datavalue,
                threshold: threshold
              })
            }
          }
        }
      }

      var rows_length = rows.length;
      if (rows_length > 10) {
        rows_length = 10;
        this.options.height = 460;
      }
      this.data = rows;
      this.setupImageStyle(rows_length);
      return;
    },
    getSensors() {
      /*var filter = {
        where: {
          device_guid: this.device_guid
        },
        limit: 1,
        order: 'created_at desc'
      }
      this.$store.dispatch('rest/find', {model:'sensors',filter:filter})
        .then((res) => {
          var filter = {
            guid:this.device.site_guid
          }
          this.site = _.find(this.sites, filter) || this.site;
          console.log(this.site);
          this.sensor = _.isEmpty(res) ? res : _.first(res)
          this.getTableControl()
        })
        .catch(err => console.log(err.toString()))*/
        var filter = {
          guid:this.device.site_guid
        }
        this.site = _.find(this.sites, filter) || this.site;
        this.sensor = this.device.sensor;
        this.getTableControl();
    },
    getImages() {
      switch (this.device.service_type) {
        case 'sis.leaf': {
          return this.getLeafImages();
        }
        default: {
          //return this.getDeviceImages();
          return this.getDeviceImages();
        }
      }
    },
    getContrlImages() {
      var self = this
      var selected_device_guid = this.device_guid;
      this.editFile = {};
      /*****************************************************/
      //var dates = this.$parent.getDates();
      /*****************************************************/
      //var from = moment(row.created_at).format('YYYY-MM-DD HH:mm:ss');
      //var to = moment(row.created_at).format('YYYY-MM-DD HH:mm:ss');

      var filter = {
        where: {
          and: [{
              device_guid: selected_device_guid
            },
          ]
        },
        order: 'created_at desc',
        limit: 1,
      }
      this.$store.dispatch('rest/find', {model:'photos',filter:filter})
        .then(function(response) {
          var pushImage = function(info) {
            var path = '{0}/{1}s/{2}/download/{3}'.format(loopback.defaults.baseURL, info.storage, selected_device_guid, info.file_name)
            self.editFile ={
              name: path,
              guid: selected_device_guid,
              site_guid: self.device.site_guid,
              created_at: moment(info.created_at).format('YYYY년 MM월 DD일 HH시 mm분 ss초')
            };
          }
          for (var i = 0; i < response.length; i++) {
            var info = response[i]
            if (_.isUndefined(info.storage)) continue;
            // if (_.isUndefined(info.file.crop)) continue;
            pushImage(info);
          }
        })
    }, 
    getDeviceImages() {
      var self = this;
      var download_url = '{0}/{1}/{2}/download'.format(loopback.defaults.baseURL, this.storage, this.device_guid);
      /*if (this.service_type == 'air' || this.service_type == 'srds') {
        download_url = download_url.replace('storages', 'flowPhotos');
        this.storage = 'flowPhotos'
      }*/

      var path = '{0}/{1}/files'.format(this.storage, this.device_guid);
      loopback
        .get(path)
        .then(res => {
          var data = _.sortBy(res, ['ctime']).reverse(); // desc : .reverse()
          this.deviceImages = [];

          data.forEach(function(file, index) {
            if(index > 10){
              return;
            }
            self.deviceImages.push({
              url: download_url + '/' + file.name,
              created_at: moment(file.ctime).format('YYYY년 MM월 DD일 HH시 mm분 ss초')
            })
          })
          if (this.deviceImages.length == 0) {
            var network_type = this.device.network_type;
            download_url = 'img/tech9_dev.png'; 
            switch (network_type.toLowerCase()) {
              case 'nb-iot': case 'cat.m1': download_url = 'img/tech9_plug.png'; break; 
              case 'lora': download_url = 'img/tech9_lora.jpg'; break;
            }
            this.deviceImages.push({
              url: download_url
            })
          }
        })
        .catch(err => {
          console.log('Device::getDeviceImages Error: ', err.toString())
        })
    },
    getLeafImages() {
      var self = this
      this.deviceImages = [];
      var service_type = this.device.service_type;
      if (service_type === 'sis.leaf') {
        this.storage = 'leafPhotos'
        /*****************************************************/
        var dates = this.$parent.getSearchDates();
        /*****************************************************/
        var from = moment(dates[0]).format('YYYY-MM-DD HH:mm:ss');
        var to = moment(dates[1]).format('YYYY-MM-DD HH:mm:ss');

        var filter = {
          where: {
            and: [{
                device_guid: this.device_guid
              },
              {
                created_at: {
                  "gte": from
                }
              },
              {
                created_at: {
                  "lt": to
                }
              }
            ]
          },
          order: 'created_at desc'
        }

        this.$store.dispatch('rest/find', {model:'photos',filter:filter})
          .then(function(response) {
            var pushImage = function(info) {
              var file = _.head(info.files.mark)
              var path = '{0}/{1}s/{2}/download/{3}'.format(loopback.defaults.baseURL, info.storage, self.device_guid, file.name)
              var label = info.predict.label;
              self.deviceImages.push({
                url: path,
                label: LEAF_TYPES[label] || label,
                created_at: moment(info.created_at).format('MM월 DD일 HH시 mm분')
              })
            }
            for (var i = 0; i < response.length; i++) {
              var info = response[i]
              if (_.isUndefined(info.storage)) continue;
              // if (_.isUndefined(info.file.crop)) continue;
              pushImage(info);
            }

            if (response.length == 0) {
              var images = self.device.images
              images = _.sortBy(images, ['created_at']).reverse();
      
              for (var i = 0; i < images.length; i++) {
                var info = images[i]
                if (_.isUndefined(info.storage)) continue
                // if (_.isUndefined(info.files.crop)) continue
                pushImage(info)
              }
            }

            if (self.deviceImages.length == 0) {
              self.deviceImages.push({
                url: "img/tech9_plug.png",
                caption: "tech9"
              });
            }
          })
      }
    },
  }
}
</script>

<style device>
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.50rem;
}
.img-responsive{ 
  display: block; 
  max-width: 100%; 
  height: auto; 
} 
.threshold-text-box { 
  position: absolute; 
  height: 30%; 
  color: white; 
  text-align: center; 
  width: 100%; 
  margin: auto; 
  top: 0; 
  bottom: 0; 
  right: 0; 
  left: 0; 
  font-size: 30px; 
} 
.threshold-dataNumber { 
  margin-top: auto; 
  font-size: 12px; 
 
} 
.containerBox { 
  position: relative; 
  display: inline-block; 
} 

</style>
