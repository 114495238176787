<template>
  <KModal
    :show.sync="showModal"
    :no-close-on-backdrop="true"
    :centered="true"
    title="Create Device Dialog"
    color="info"
    size="lg"
  >
    <template #header>
      <h6 class="modal-title text-center">{{title}} 기준 설정</h6>
    </template>

    <div class='modal-body'>
      <div class="gaugeForm">
        <CRow>
          <CCol col="12" xl="6">
            <Gauge class="xGauge" title="전ㆍ후방 현재 기울기(x축)" :value="this.sensorDiffX()" :thresholds="this.thresholds" />
          </CCol>
          <CCol col="12" xl="6">
            <Gauge class="yGauge" title="좌ㆍ우측 현재 기울기(y축)" :value="this.sensorDiffY()" :thresholds="this.thresholds" />
          </CCol>
        </CRow>
      </div>
      <form name='thresholdForm'>
        <div class='form-group'>
          <div class='row d-sm-down-none'>
            <div class='col-sm-3 col-lg-3'>
              <label>상태</label>
            </div>
            <div class='col-sm-3 col-lg-3'>
              <label>최소값(도) <strong class='text-danger'>*</strong></label>
            </div>
            <div class='col-sm-3 col-lg-3'>
              <label>최대값(도) <strong class='text-danger'>*</strong></label>
            </div>
            <div class='col-sm-3 col-lg-3'>
              <label>푸쉬알림</label>
            </div>
          </div>
        </div>

        <div class='form-group'>
          <div class="row" v-for="(threshold, index) in thresholds" :key="index">
            <div class='col-sm-3 col-lg-3'>
              <input type='text' class='form-control text-center' :style="{'background-color': threshold.color, 'font-weight': 'bold'}" v-model='threshold.name' readonly>
            </div>
            <div class='col-sm-3 col-lg-3'>
              <input type='number' style="font-size:12px;" class='form-control' min=0 max=360 v-model='threshold._min' @change='changeRange(index, threshold, true)' :step=0.1>
            </div>

            <div class='col-sm-3 col-lg-3'>
              <input type='number' style="font-size:12px;" class='form-control' min=0 max=360 v-model='threshold._max' @change='changeRange(index, threshold, false)' :step=0.1>
            </div>
            <div class='col-sm-3 col-lg-3'>
              <CSwitch class="mr-1" color="info" :checked.sync="threshold.is_push" />
            </div>
          </div>
        </div>
      </form>
    </div>

    <template #footer>
      <CButton @click="register()" color="info">저장</CButton>
      <CButton @click="cancel()" color="light">취소</CButton>
    </template>

    <Notify ref="notifyDialog"/>

  </KModal>
</template>

<script>
import loopback from '@/services/loopback';
import Threshold from '@/views/device/Threshold'
import Gauge from "@/views/srds/device/Gauge"

export default {
  name: 'SRDSThreshold',
  extends: Threshold,
  components: {
    Gauge
  },
  methods: {
    sensorDiffX() {
      console.warn = console.error = () => {};

      let standard = _.get(this.device, "standard");

      /* 현재값 */
      let xData = _.get(_.find(this.device.sensor.data, { type: 8, id: 1 }), 'value', null);
      
      /* 차이값 */
      let xDiff = standard.x - xData;
      return xDiff
      
    },
    sensorDiffY() {
      console.warn = console.error = () => {};
      
      let standard = _.get(this.device, "standard");

      /* 현재값 */
      let yData = _.get(_.find(this.device.sensor.data, { type: 8, id: 2 }), 'value', null);
      
      /* 차이값 */
      let yDiff = standard.y - yData;
      return yDiff
    }
  }
}
</script>

<style scoped lang="scss">
.modal-body {
  .gaugeForm {
    display: flex;
  }
  .form-group {
    margin-left: 10%;
  }
}

</style>