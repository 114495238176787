<template>
  <div>
    <h2 class="table__title">흡착탑 상태</h2>
        <table class="table table-bordered table-striped text-center" style="font-size: 14px;">
            <thead>
                <tr>
                    <th scope="col" colspan="2">항목</th>
                    <th scope="col">흡착탑#1</th>
                    <th scope="col">흡착탑#2</th>
                    <th scope="col">흡착탑#3</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th scope="row" colspan="2">차압(mmH20)</th>
                    <td v-for="(value, index) in sensor_datas[0]" :key="index">{{ value }}</td>
                </tr>
                <tr>
                    <th scope="row" colspan="2">자켓히터 (℃)</th>
                    <td v-for="(value, index) in sensor_datas[1]" :key="index">{{ value }}</td>
                </tr>
                <tr>
                    <th scope="row" colspan="2">모듈온도 (℃)</th>
                    <td v-for="(value, index) in sensor_datas[2]" :key="index">{{ value }}</td>
                </tr>
                <tr>
                    <th scope="row" colspan="2">라인히터 (℃)</th>
                    <td v-for="(value, index) in sensor_datas[3]" :key="index">{{ value }}</td>
                </tr>
            </tbody>
        </table>
        <CRow>
            <CCol>
                <CCard style="height: 150px; border-radius:14px;">
                    <CRow>
                        <CCol>
                            <h5 style="margin-top: 2%; text-align: center;">흡착탑 #1</h5>
                        </CCol>
                    </CRow>
                    <br />
                    <CRow>
                        <CCol>
                            <div class="test1">
                                <i :class="top_status[Number(tower_status[0])]"></i>
                            </div>
                            <div class="test2">{{top_text[Number(tower_status[0])]}}</div>
                        </CCol>
                    </CRow>
                </CCard>
            </CCol>
            <CCol>
            <CCard style="height: 150px; border-radius:14px;">
                <CRow>
                    <CCol>
                        <h5 style="margin: 2%; text-align: center;">흡착탑 #2</h5>
                    </CCol>
                </CRow>
                <br />
                <CRow>
                    <CCol>
                        <div class="test1">
                            <i :class="top_status[Number(tower_status[1])]"></i>
                        </div>
                        <div class="test2">{{top_text[Number(tower_status[1])]}}</div>
                    </CCol>
                </CRow>
                </CCard>
            </CCol>
            <CCol>
                <CCard style="height: 150px; border-radius:14px;">
                    <CRow>
                        <CCol>
                        <h5 style="margin: 2%; text-align: center;">흡착탑 #3</h5>
                        </CCol>
                    </CRow>
                    <br />
                    <CRow>
                    <CCol>
                        <div class="test1">
                            <i :class="top_status[Number(tower_status[2])]"></i>
                        </div>
                            <div class="test2">{{top_text[Number(tower_status[2])]}}</div>
                        </CCol>
                    </CRow>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>

export default {
    props: {
        device: {
            type: Object
        }
    },
    data () {
        return {
            sensor_datas: [0,0,0,0,0,0,0,0,0,0,0,0],
            top_status: ["fa-regular fa-circle-stop fa-fade fa-xl", "fa-solid fa-filter fa-beat-fade fa-xl", "fa-solid fa-arrows-spin fa-spin fa-xl", "fa-solid fa-wind fa-beat-fade fa-xl"],
            top_text: ["대기중", "흡착중", "탈착중","냉각중"],
            tower_status: [0,0,0]
        }
    },    
    watch: {
        device: function(new_val, old_val) {
            this.sensorValue()
        }
    },    
    methods: {
        sensorValue() {
            let value = this.device.sensor.data
            console.log("value : ", value)
            let arr1 = value[0].value
            let arr4 = _.isUndefined(value[3]) ? this.tower_status : value[3].value
            let result = this.splitIntoChunk(arr1, 3)
            //console.log("result : ", arr4)
            this.sensor_datas = result;
            this.tower_status = arr4;
        },        
        splitIntoChunk(arr, chunk) {
            const result = []

            for(let i=0; i < arr.length; i+=chunk) {
                let tempArray;
                tempArray = arr.slice(i, i + chunk);

                result.push(tempArray)
            }

            return result
        }
    },
}
</script>

<style scoped lang="scss">
.table__title {
    text-align: center;
    background-color: #0e1575;
    color: white;
    border-radius: 16px 16px 0 0;
    height: 40px;
    line-height: 40px;
}
.test1 {
    margin-bottom: 8%;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 22px;
}
.test2 {
    // border: 1px solid blue;
    text-align: center;
    font-size: 28px;
    font-weight: bold;    
}

</style>