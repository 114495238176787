<template>
  <KModal
    :show.sync="showModal"
    :no-close-on-backdrop="true"
    :centered="true"
    title="Remote Dialog"
    color="info"
    size="lg"
  >
    <template #header>
      <h6 class="modal-title text-center">공정설정</h6>
    </template>

    <div class='modal-body'>
        <!-- <CRow v-if="which!='man'">
            <CCol col="12" xl="12">
                <label><strong>원격제어 설정</strong></label><br/>
                <label><strong style="color:red;">* 경고: </strong><strong>본 제어는 시설 점검을 위한 기능임.</strong></label><br/>
                <label><strong style="color:red;">* 반드시 THC 및 가스 유입 없는 상태에서 진행할 것</strong></label>
            </CCol>
        </CRow> -->

        <form name="sensor">
            <CRow>
                <CCol>
                    <div class="mainTitle">초기운전조건</div>
                    <CRow>
                        <CCol col="12" lg="12">
                            <CRow style="border: 1px solid #d9d9d9;">
                                <CCol col="12" xl="5" >
                                    <div class="initialConditions">
                                        <div class="left">
                                            <div class="content">
                                                <div class="subTitle">MFC1 Setpoint</div><input type='number' min=0.00 v-model='mfcset[0]' :step='0.01'>
                                            </div>
                                            <div class="content">
                                                <div class="subTitle">MFC2 Setpoint</div><input type='number' min=0.00 v-model='mfcset[1]' :step='0.01'>
                                            </div>
                                            <div class="content">
                                                <div class="subTitle">MFC3 Setpoint</div><input type='number' min=0.00 v-model='mfcset[2]' :step='0.01'>
                                            </div>
                                            <div class="content">
                                                <div class="subTitle">MFC4 Setpoint</div><input type='number' min=0.00 v-model='mfcset[3]' :step='0.01'>
                                            </div>
                                            <div class="content">
                                                <div class="subTitle">MFC5 Setpoint</div><input type='number' min=0.00 v-model='mfcset[4]' :step='0.01'>
                                            </div>
                                        </div>
                                    </div>
                                </CCol>
                                <CCol col="12" xl="6">
                                    <br />
                                    <div class="initialConditions">
                                        <div class="right">
                                            <div class="content">
                                                <div class="subTitle">TC4 Setpoint</div><input type='number' min=0.00 v-model='tcsetpoint[3]' :step='1'>
                                            </div>
                                            <div class="content">
                                                <div class="subTitle">Step별 최소 유지시간(sec)</div><input type='number' min=0.00 v-model='steptime' :step='1'>
                                            </div>
                                            <div class="content">
                                                <div class="subTitle">반복 Cycle 수</div><input type='number' min=0.00 v-model='cycle' :step='1'>
                                            </div>
                                            <div class="content">
                                                <div class="subTitle">농도확인 최소 대기시간(sec)</div><input type='number' min=0.00 v-model='waittime' :step='1'>
                                            </div>
                                        </div>
                                    </div>
                                </CCol>
                            </CRow>
                            <!-- <div class="button">
                                <button>Reset</button>
                            </div> -->
                        </CCol>
                    </CRow>
                    <br />
                    <CRow>
                        <CCol>
                            RX1 Operation Condition
                            <CRow>
                                <CCol>
                                    <div class="rx1Condition">
                                        <div class="condition">
                                            <div class="subTitle">농도2 목표 농도</div><input type='number' min=0.00 v-model='denssetpoint2[0]' :step='1'>
                                        </div>
                                        <div class="condition">
                                            <div class="subTitle">농도3 목표 농도</div><input type='number' min=0.00 v-model='denssetpoint3[0]' :step='1'>
                                        </div>
                                        <div class="condition">
                                            <div class="subTitle">TC1 Setpoint</div><input type='number' min=0.00 v-model='tcsetpoint[0]' :step='1'>
                                        </div>
                                        <div class="condition">
                                            <div class="subTitle">TC5 Setpoint</div><input type='number' min=0.00 v-model='tcsetpoint[4]' :step='1'>
                                        </div>
                                        <div class="condition">
                                            <div class="subTitle">TC1 목표 온도</div><input type='number' min=0.00 v-model='tempset[0]' :step='1'>
                                        </div>
                                    </div>
                                </CCol>
                            </CRow>
                        </CCol>
                        <CCol>
                            RX2 Operation Condition
                            <CRow>
                                <CCol>
                                    <div class="rx1Condition">
                                        <div class="condition">
                                            <div class="subTitle">농도2 목표 농도</div><input type='number' min=0.00 v-model='denssetpoint2[1]' :step='1'>
                                        </div>
                                        <div class="condition">
                                            <div class="subTitle">농도3 목표 농도</div><input type='number' min=0.00 v-model='denssetpoint3[1]' :step='1'>
                                        </div>
                                        <div class="condition">
                                            <div class="subTitle">TC2 Setpoint</div><input type='number' min=0.00 v-model='tcsetpoint[1]' :step='1'>
                                        </div>
                                        <div class="condition">
                                            <div class="subTitle">TC6 Setpoint</div><input type='number' min=0.00 v-model='tcsetpoint[5]' :step='1'>
                                        </div>
                                        <div class="condition">
                                            <div class="subTitle">TC2 목표 온도</div><input type='number' min=0.00 v-model='tempset[1]' :step='1'>
                                        </div>
                                    </div>
                                </CCol>
                            </CRow>
                        </CCol>
                        <CCol>
                            RX3 Operation Condition
                            <CRow>
                                <CCol>
                                    <div class="rx1Condition">
                                        <div class="condition">
                                            <div class="subTitle">농도2 목표 농도</div><input type='number' min=0.00 v-model='denssetpoint2[2]' :step='1'>
                                        </div>
                                        <div class="condition">
                                            <div class="subTitle">농도3 목표 농도</div><input type='number' min=0.00 v-model='denssetpoint3[2]' :step='1'>
                                        </div>
                                        <div class="condition">
                                            <div class="subTitle">TC3 Setpoint</div><input type='number' min=0.00 v-model='tcsetpoint[2]' :step='1'>
                                        </div>
                                        <div class="condition">
                                            <div class="subTitle">TC7 Setpoint</div><input type='number' min=0.00 v-model='tcsetpoint[6]' :step='1'>
                                        </div>
                                        <div class="condition">
                                            <div class="subTitle">TC3 목표 온도</div><input type='number' min=0.00 v-model='tempset[2]' :step='1'>
                                        </div>
                                    </div>
                                </CCol>
                            </CRow>
                        </CCol>
                    </CRow>
                </CCol>
            </CRow>
        </form>
    </div>

    <template #footer>
      <CButton @click="execute()" color="info">저장</CButton>
      <CButton @click="cancel()" color="light">취소</CButton>
    </template>

    <Notify ref="notifyDialog"/>
    
  </KModal>
</template>

<script>

import WashingDialog from "@/views/btfs/WashingDialog"
import loopback from '@/services/loopback';

import {
  REMOTE_CONTROL_TYPE
} from "@/views/thcs/remoteSettings";

export default {
  name: 'THCSProcessSettingsDialog',
  extends: WashingDialog,
  data () {
    return {
      type: 'thcs',
      device: {},
      mfcset: [0.30, 0.70, 0.00, 0.00, 1.00],
      tcsetpoint: [100, 100, 100, 0, 300, 300, 300],
      tempset: [50, 50, 50],
      denssetpoint2: [10, 10, 10],
      denssetpoint3: [0, 0, 0],
      steptime: 20,
      cycle: 3,
      waittime: 10,
    }
  },
  methods: {
    execute() {
      var where = {
        _id: this.device.guid
      }
      var data = {
        standard: {
            mfc: this.mfcset,
            tc: this.tcsetpoint,
            temp: this.tempset,
            dens2: this.denssetpoint2,
            dens3: this.denssetpoint3,
            steptime: this.steptime,
            cycle: this.cycle,
            waittime: this.waittime,
        }
      }

      this.$store.dispatch('rest/upsertWithWhere', {model:'devices',where:where,data:data})
        .then(res => {
          let msg = {
            name: this.$options.name,
            which: this.which,
            data: res
          }
          this.$emit('update', msg)
          this.showModal = false;
        })      
        .catch(err => {
          console.log('Threshold::register error:'. err.toString())
          this.showModal = false;
        })
    },
    show(which='auto', device) {
        var self = this
        var type = _.get(device, "service_type", "thcs"); // || submerged
        if (!_.get(REMOTE_CONTROL_TYPE, type)) {
            console.log('WashingDialog::show error - unknown type:', type);
            return;
        }
        return loopback.get('/devices/'+device.guid)
            .then(res => {
                this.which = which
                this.type = type
                this.device = res;
                this.title = 'THC 처리기';
                this.mfcset = !_.isUndefined(this.device.standard.mfc) ? this.device.standard.mfc : mfcset;
                this.tcsetpoint = !_.isUndefined(this.device.standard.tc) ? this.device.standard.tc : tc;
                this.tempset = !_.isUndefined(this.device.standard.temp) ? this.device.standard.temp : tempset;
                this.denssetpoint2 = !_.isUndefined(this.device.standard.dens2) ? this.device.standard.dens2 : denssetpoint2;
                this.denssetpoint3 = !_.isUndefined(this.device.standard.dens3) ? this.device.standard.dens3 : denssetpoint3;
                this.showModal = true
                setTimeout(function() {
                    //self.timeTrigger()
                    //self.switchTrigger()
                }, 100)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.mainTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0.5%;
}
.initialConditions {
    display: flex;
    .left {
        .content {
            padding: 2px;
            display: flex;
            .subTitle {
                font-size: 16px;
                text-align: center;
                width: 150px;
                font-weight: bold;
            }
            > h5 {
                width: 150px;
                text-align: center;
                // margin-right: 40px;
            }
            > input {
                width: 80px;
                text-align: center;
            }
        }
    }
    .right {
        .content {
            padding: 2px;
            display: flex;
            .subTitle {
                font-size: 16px;
                text-align: center;
                width: 240px;
                font-weight: bold;
            }
            > h5 {
                width: 240px;
                text-align: center;
                // margin-right: 40px;
            }
            > input {
                width: 90px;
                text-align: center;
            }
        }
    }
    .button {
        font-size: 18px;
    }
}
.rx1Condition {
    border: 1px solid #d9d9d9;
    .condition {
        padding: 2px;
        display: flex;
        .subTitle {
            font-size: 16px;
            text-align: center;
            width: 150px;
            font-weight: bold;
        }
        > input {
            width: 60px;
            text-align: center;
        }
    }
}
</style>