<template>
  <div>
    <br />
    <CRow>
      <CCol col="12" xl="5">
        <NewDevice ref="deviceView" :device.sync="device" prefix="thcs_" />
      </CCol>
      <CCol col="12" xl="2">
        <GasInput :device.sync="device" />
        <CRow>
            <CCol>
                <CCard>
                    <CCol col="12" xl="12">
                        <button type="button" class="btn btn-success btn" style="margin: 1.4%; width: 100%; height: 44px;" @click="openSettings('remote')"><i class="fa-solid fa-play"></i> 공정시작</button>
                    </CCol>
                    <CCol col='12' xl="12">
                        <button type="button" class="btn btn-warning" style="margin: 1.4%; width: 100%; height: 44px;" @click="openSettings('setting')"><i class="fa-solid fa-gear"></i> 공정설정</button>
                    </CCol>
                    <CCol col='12' xl="12">
                        <button type="button" class="btn btn-primary" style="margin: 1.4%; width: 100%; height: 44px;" @click="openSettings('valvetest')"><i class="fa-regular fa-circle-check"></i> 벨브테스트</button>
                    </CCol>
                </CCard>
            </CCol>
        </CRow>
      </CCol>
      <CCol col="12" xl="5">
        <MonitorTable :device.sync="device" :device_guid.sync="device_guid" />
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" xl="12">
        <Status :device.sync="device" />
      </CCol>
    </CRow>
    <RemoteDialog ref="remoteDialog" />
    <ValveTestDialog ref="valveTestDialog" />
    <ProcessSettingsDialog ref="processSettingsDialog" />
  </div>
</template>

<script>

import GasInput from '@/views/thcs/GasInput'
import NewDevice from '@/views/thcs/NewDevice'
import Status from '@/views/thcs/Status'
import MonitorTable from '@/views/thcs/MonitorTable'

import ValveTestDialog from '@/views/thcs/ValveTestDialog';
import ProcessSettingsDialog from '@/views/thcs/ProcessSettingsDialog';
import RemoteDialog from '@/views/thcs/RemoteDialog';


export default {
    components: {
        NewDevice,
        Status,
        MonitorTable,
        GasInput,
        ValveTestDialog,
        ProcessSettingsDialog,
        RemoteDialog
    },
    props: {
      device: {
        type: Object
      },
      device_guid: {
        type: String
      }
    },
    watch: {
      device: function(new_val, old_val) { 
        console.log('Test new, old : ', new_val, old_val)
      }
    },        
    methods: {
      openSettings(which) {
          //console.log('RemoteControl::openSettings which:', this.device)
          if(which === 'valvetest') {
            this.$refs.valveTestDialog.show(which, this.device);
          }
          if(which === 'setting') {
            this.$refs.processSettingsDialog.show(which, this.device);
          }
          if(which === 'remote') {
            this.$refs.remoteDialog.show(which, this.device);
          }
      },
    }    
}
</script>

<style lang="scss" scoped>
.btn {
  font-size: 16px;
}
</style>